<template>
  <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
    <template #header>
      <view-search-form :queryInfo.sync="queryInfo" @on-search="renderTable(1)" keyword-placeholder="姓名"
        :filter-keys="['teacherType']" :tool-list="['keyword', 'gradeName', 'term']">
        <el-form-item label="职工类别">
          <el-select size="small" v-model="queryInfo.teacherType" placeholder="请选择">
            <el-option v-for="item in memberTypeList" :key="item.type" :label="item.name" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门" v-permission="['infoCollect:personcollct:all']">
          <el-select v-model="queryInfo.deptId" clearable filterable size="small">
            <el-option v-for="( item, index ) in  departmentList " :key="index" :label="item.deptName" :value="item.id" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <el-radio-group v-model="queryInfo.teacherType" @change="orderTypeChange">
        <el-radio-button v-for="{ type, name } in memberTypeList" :key="type" :label="type">{{ name
        }}
        </el-radio-button>
      </el-radio-group>
      <div class="header-button mb-1">
      </div>
    </template>
    <el-table v-if="visibleTable" height="72vh" :data="tableData" class=" mt-1" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="deptName" label="部门" min-width="150"></el-table-column>
      <el-table-column prop="teacherNo" label="职工号" min-width="110"></el-table-column>
      <el-table-column prop="gradeName" label="学年" min-width="100"></el-table-column>
      <el-table-column prop="term" label="学期">
        <template v-slot="{ row }">
          {{ row.term ? `第${row.term}学期` : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="realName" label="姓名" width="70"></el-table-column>
      <el-table-column show-overflow-tooltip prop="teacherTermTimeRecord" label="出勤天数">
        <template v-slot="{ row }">
          {{ row.teacherTermTimeRecord ? `${row.teacherTermTimeRecord.workDayNum}天` : '' }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="teacherTermTimeRecord" label="旷工天数">
        <template v-slot="{ row }">
          {{ row.teacherTermTimeRecord ? `${row.teacherTermTimeRecord.missDayNum}天` : '' }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="teacherTermTimeRecord" label="请假天数">
        <template v-slot="{ row }">
          {{ row.teacherTermTimeRecord ? `${row.teacherTermTimeRecord.leaveDayNum}天` : '' }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="selfOpinion" label="自我诊断意见" min-width="120"></el-table-column>
      <el-table-column show-overflow-tooltip prop="improveWayResult" label="改进措施及成效" min-width="120"></el-table-column>
      <el-table-column show-overflow-tooltip prop="stuEmergencyRecord" label="(学生)突发事件处理记录"
        min-width="180"></el-table-column>
      <el-table-column show-overflow-tooltip prop="awards" label="个人获奖情况" min-width="110"></el-table-column>
      <el-table-column show-overflow-tooltip prop="nextTermPlan" label="下学期工作计划" min-width="120"></el-table-column>
      <el-table-column prop="courseRecord" label="教育教学"></el-table-column>
      <el-table-column prop="paperResult" label="论文"></el-table-column>
      <el-table-column prop="patent" label="专利"></el-table-column>
      <el-table-column prop="articleRecord" label="稿件"></el-table-column>
      <el-table-column prop="guidedCompetitionAwards" label="竞赛"></el-table-column>
      <el-table-column prop="projectResult" label="课题"></el-table-column>
      <!-- 行政人员 -->
      <template v-if="queryInfo.teacherType !== '行政人员'">

        <el-table-column prop="studentActivity" label="学生活动"></el-table-column>
      </template>
      <!-- 专任教师   -->
      <template v-if="queryInfo.teacherType === '教师'">
        <el-table-column prop="work" label="著作"></el-table-column>
        <el-table-column prop="supervisedGraduateInternship" min-width="120" label="毕业生顶岗实习"></el-table-column>
      </template>

      <!-- 中层领导 -->
      <template v-if="queryInfo.teacherType === '行政人员' || queryInfo.teacherType === '中层干部'">
        <el-table-column prop="goOutWork" label="外出公干"></el-table-column>
      </template>

      <!-- 辅导员   -->
      <template v-if="queryInfo.teacherType === '辅导员'">
        <el-table-column prop="classCollectSituation" label="班级情况"></el-table-column>
        <el-table-column prop="classArrearSituation" label="欠费情况"></el-table-column>
      </template>
      <el-table-column v-if="queryInfo.teacherType !== '辅导员'" prop="socialService" label="社会服务"
        width="120"></el-table-column>

      <el-table-column prop="socialPracticeTrain" label="社会实践培训" width="150"></el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template v-slot="{ row }">
          <el-button type="text" @click="showDialog(row.id)">
            查看
          </el-button>
          <el-button type="text" @click="showSubDialog('visibleJobDutyDialog', 'dialogJobDutyRef', row.userId)">
            查看岗位职责
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <dialog-add-person-collect :isOnlyReadteacherType="queryInfo.teacherType" :isOnlyRead="true" ref="dialogRef"
      :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
    <dialog-job-duty :isOnlyRead="true" ref="dialogJobDutyRef" :visible.sync="visibleJobDutyDialog"
      v-if="visibleJobDutyDialog" />
  </table-view>
</template>

<script>
import DialogAddPersonCollect from './components/DialogAddPersonCollect.vue'
import DialogJobDuty from './components/DialogJobDuty'
import { pagePersonCollecApi } from '@/api/infoCollect/personCollect-api.js'
import tableView from '@/vue/mixins/table-view'
import { memberTypeList } from './components/options'
import store from '@/store/index'

export default {
  name: 'personCollectSummary',
  mixins: [tableView],
  components: {
    DialogAddPersonCollect,
    DialogJobDuty
  },

  data() {
    return {
      memberTypeList,
      tableData: [],
      queryInfo: {
        teacherType: '教师',
        gradeName: null,
        term: null,
        deptId: null
      },
      departmentList: '',
      visibleTable: true,
      visibleJobDutyDialog: false
    }
  },
  mounted() {
    this.initDate()
    this.renderTable()
    this.getDepartment()
  },
  methods: {
    async renderTable(pageNum) {
      this.getAll()

      if (pageNum) {
        this.visibleTable = false
        await this.getTableData(pagePersonCollecApi, pageNum)
        this.visibleTable = true
      } else {
        await this.getTableData(pagePersonCollecApi, pageNum)
      }
    },
    async orderTypeChange() {
      await this.renderTable(1)
    },
    // 查看全部的权限
    getAll() {
      const _permission = store.getters['login/getPermission']

      if (!(_permission.includes('infoCollect:personcollct:all'))) {
        this.queryInfo.deptId = this.$store.state.login.userInfo.deptId
      }
    },
    // 获取部门
    getDepartment() {
      this.$http.getDepartmentListQuery({}).then((res) => {
        this.departmentList = res.data
      })
    },
    // 获取当年学期、学年
    initDate() {
      const time = new Date()
      const year = time.getFullYear()
      const month = time.getMonth() + 1

      // 当年3月-当年8月为第二学期，当年9月-次年2月为第一学期
      if (month >= 3 && month < 9) {
        this.queryInfo.term = '2'
        this.queryInfo.gradeName = `${year - 1}-${year}`
      } else if (month >= 9) {
        this.queryInfo.term = '1'
        this.queryInfo.gradeName = `${year}-${year + 1}`
      } else {
        this.queryInfo.term = '1'
        this.queryInfo.gradeName = `${year - 1}-${year}`
      }
    }
  }
}
</script>

<style></style>
